var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "emaillogs3281",
          tooltipMsg: "manualforlogsof",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "PeslogId", ascending: 0 },
          modelId: "PetaskId",
          showActionEdit: false,
          addNewRecordMsg: "",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.left",
                          modifiers: { hover: true, left: true },
                        },
                      ],
                      attrs: { title: _vm.$t("previewsente-ma") },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "detail-table-icon",
                        attrs: { icon: "eye", cursor: "pointer", size: "lg" },
                        on: {
                          click: function ($event) {
                            return _vm.openEmailSendModal(list.row)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "PeslogType",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm.peslogTypeTexts[list.row.PeslogType].Text)),
                ]
              },
            },
            {
              key: "PeslogDatetime",
              fn: function (list) {
                return [
                  _vm._v(_vm._s(_vm._f("dateTime")(list.row.PeslogDatetime))),
                ]
              },
            },
            {
              key: "afterTable",
              fn: function () {
                return [
                  _c("email-send-modal", {
                    attrs: {
                      enableSending: false,
                      isVisible: _vm.showEmailSend,
                      typeId: _vm.modalTypeId,
                      sendLogEmailId: _vm.modalSendLogEmailId,
                      templateId: _vm.modalTemplateId,
                      entityId: _vm.modalEntityId,
                      isSentEmail: true,
                    },
                    on: {
                      onClose: function ($event) {
                        _vm.showEmailSend = false
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          709893603
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }