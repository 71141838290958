import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'PeslogDatetime',
		i18n: 'sent',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'PeslogType',
		i18n: 'type2877',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'PeslogSubject',
		i18n: 'subject',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'PeslogRecipients',
		i18n: 'to2339',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'UserName',
		i18n: 'from',
		sortable: true,
		filter: 'text',
	},
];
