<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="emaillogs3281"
		tooltipMsg="manualforlogsof"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'PeslogId', ascending: 0 }"
		modelId="PetaskId"
		:showActionEdit="false"
		addNewRecordMsg
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
	>
		<template #customActions="list">
			<div v-b-tooltip.hover.left :title="$t('previewsente-ma')">
				<font-awesome-icon
					icon="eye"
					cursor="pointer"
					class="detail-table-icon"
					size="lg"
					@click="openEmailSendModal(list.row)"
				></font-awesome-icon>
			</div>
		</template>

		<template #PeslogType="list">{{ peslogTypeTexts[list.row.PeslogType].Text }}</template>

		<template #PeslogDatetime="list">{{ list.row.PeslogDatetime | dateTime }}</template>

		<template #afterTable>
			<email-send-modal
				:enableSending="false"
				:isVisible="showEmailSend"
				:typeId="modalTypeId"
				:sendLogEmailId="modalSendLogEmailId"
				:templateId="modalTemplateId"
				:entityId="modalEntityId"
				:isSentEmail="true"
				@onClose="showEmailSend = false"
			/>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import serviceEnums from '@/services/service/enums.service';
import { setDropdownOptions } from '@/components/general/utils';

import EmailSendModal from '@/modules//emails/email-send.modal';
import { tableColumns } from './emails-logs.table-data';

export default {
	name: 'PortalEmailSendLogTable',

	components: {
		TablePage,
		EmailSendModal,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'PortalEmailSendLogListItem',
			peslogTypeTexts: serviceEnums.getEnumDict('emailSendLogType'),

			modalTypeId: null,
			modalSendLogEmailId: null,
			modalTemplateId: null,
			modalEntityId: null,
			showEmailSend: false,
		};
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		async prepareColumns(columns) {
			let cols = preparePeslogType(columns);

			return cols;

			function preparePeslogType(columns) {
				const values = serviceEnums.getEnumForDropdown('emailSendLogType', true);

				return setDropdownOptions(columns, 'PeslogType', values);
			}
		},

		openEmailSendModal(row) {
			this.modalTypeId = row.PeslogType;
			this.modalSendLogEmailId = row.PeslogId;
			this.modalTemplateId = row.PemailId;
			this.modalEntityId = row.PeslogHiddenId;
			this.showEmailSend = true;
		},
	},
};
</script>

<style lang="scss">
.detail-table-icon {
	color: var(--mu-icongray);
}
</style>
